import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UIService } from 'src/app/shared/ui.service';
import * as root from 'src/app/app.reducer';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as UserReportsActions from 'src/app/redux/user-reports/user-reports.actions';
import { takeUntil } from 'rxjs/operators';
import { UserReportDataGridData, UserReportsDateFilter, UserReportsProject, UserReportsPerson, UserReportsMarket, UserReport, UserReportData, UserReportCreateRequest, UserReportCreateResponse, UserReportTaskResponse } from 'src/app/redux/user-reports/user-reports.model';
import { OrderTask } from 'src/app/redux/order-task/order-task.model';

@Injectable(
)
export class UserReportsService {
  private _onDestroy = new Subject<void>();
  constructor(
    private store: Store<root.IRootState>,
    private http: HttpClient,
    private uiService: UIService,
  ) { }

  initService() {
    this.cancelSubscriptions();
    this._onDestroy = new Subject<void>();
  }

  cancelSubscriptions() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  resetReportsFilter() {
    this.store.dispatch(new UserReportsActions.SetInitalFilter(null));
  }

  resetProjects() {
    this.store.dispatch(new UserReportsActions.SetProjects(null));
  }

  resetCoordinators() {
    this.store.dispatch(new UserReportsActions.SetCoordinators(null));
  }

  resetMarkets() {
    this.store.dispatch(new UserReportsActions.SetMarkets(null));
  }

  resetResponsiblePersons() {
    this.store.dispatch(new UserReportsActions.SetResponsiblePersons(null));
  }

  resetAll() {
    //this.store.dispatch(new UserReportsActions.SetProjects(null));
    this.store.dispatch(new UserReportsActions.SetCoordinators(null));
    this.store.dispatch(new UserReportsActions.SetMarkets(null));
    this.store.dispatch(new UserReportsActions.SetResponsiblePersons(null));
  }

  setCoordinators(coordinatorIds: UserReportsPerson[]) {
    this.store.dispatch(new UserReportsActions.SetCoordinators(coordinatorIds));
  }

  getReportOrderTasksPromise(token: string, dataId: number, page: number, itemsPerPage: number, status?: number): Promise<UserReportDataGridData> {

    let apiLink = "";

    if (status === null || status === undefined)
    {
      apiLink = environment.apiUrl + '/ordertaskreport/report/' + token + '/data/' + dataId +
      '?page=' + page + '&itemsPerPage=' + itemsPerPage;
    }
    else
    {
      apiLink = environment.apiUrl + '/ordertaskreport/report/' + token + '/data/' + dataId +
      '?status=' + status + '&page=' + page + '&itemsPerPage=' + itemsPerPage;
    }

    return this.http.get<UserReportTaskResponse>(apiLink)
      .toPromise().then(x => {
        const dataObject: UserReportDataGridData = {
          data: x.data,
          totalCount: x.paginationData.totalCount,
          groupCount: 0,
          summary: []
        };
        return dataObject;
      });
  }

  getReportDetails(token: string) {
    this.store.dispatch(new UserReportsActions.StartLoading());
    this.http.get<UserReportData[]>(environment.apiUrl + '/ordertaskreport/report/' + token)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetReportsDetails(response));
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getReports() {
    this.store.dispatch(new UserReportsActions.StartLoading());
    this.http.get<UserReport[]>(environment.apiUrl + '/ordertaskreport/report/list')
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetReports(response));
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  createNewTask(startDate: Date, endDate: Date, managerIds: string[], projectIds: number[], coordinatorIds: string[], marketIds: number[], responsiblePersonIds: string[]) {
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);

    this.store.dispatch(new UserReportsActions.StartLoading());
    var request = {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
      "CoordinatorIds": coordinatorIds,
      "ProjectIds": projectIds,
      "MarketIds": marketIds,
      "ResponsiblePersonIds": responsiblePersonIds,
    }

    this.http.post<UserReportCreateResponse>(environment.apiUrl + '/ordertaskreport/report/create', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          if (response.isSuccess) {
            this.uiService.openSnack(response.message, 'Informacja', 10_000);
            this.getReports();
          }
          else {
            this.uiService.openSnack('Wystąpił błąd podczas wysyłania raportu', 'Informacja', 10_000);
          }
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getResponsiblePersons(startDate: Date, endDate: Date, managerIds: string[], projectIds: number[], coordinatorIds: string[], marketIds: number[], isCoordinator: boolean = false) {
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);
    this.store.dispatch(new UserReportsActions.StartLoading());
    var request = {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
      "CoordinatorIds": coordinatorIds,
      "ProjectIds": projectIds,
      "MarketIds": marketIds
    }

    var role = isCoordinator ? 'coordinator' : 'manager';
    this.http.post<UserReportsPerson[]>(environment.apiUrl + '/ordertaskreport/filter/' + role + '/responsiblePersons', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetResponsiblePersons(response));
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getMarkets(startDate: Date, endDate: Date, managerIds: string[], projectIds: number[], coordinatorIds: string[], isCoordinator: boolean = false) {
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);

    this.store.dispatch(new UserReportsActions.StartLoading());
    var request = {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
      "ProjectIds": projectIds,
      "CoordinatorIds": coordinatorIds
    }

    var role = isCoordinator ? 'coordinator' : 'manager';
    this.http.post<UserReportsMarket[]>(environment.apiUrl + '/ordertaskreport/filter/' + role + '/markets', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetMarkets(response));
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getCoordinators(startDate: Date, endDate: Date, managerIds: string[], projectIds: number[], isCoordinator: boolean = false) {
    if (isCoordinator) {
      return;
    }
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);

    this.store.dispatch(new UserReportsActions.StartLoading());
    var request = {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
      "ProjectIds": projectIds
    }

    var role = 'manager';
    this.http.post<UserReportsPerson[]>(environment.apiUrl + '/ordertaskreport/filter/' + role + '/coordinators', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetCoordinators(response));
          this.store.dispatch(new UserReportsActions.StopLoading());

        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getProjects(startDate: Date, endDate: Date, managerIds: string[], coordinatorIds: string[], isCoordinator: boolean = false) {
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);

    this.store.dispatch(new UserReportsActions.StartLoading());

    var request = isCoordinator === true ?
    {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
      "CoordinatorIds": coordinatorIds
    }
    :
    {
      "StartDate": startDateString,
      "EndDate": endDateString,
      "ManagerIds": managerIds,
    };

    var role = isCoordinator ? 'coordinator' : 'manager';
    this.http.post<UserReportsProject[]>(environment.apiUrl + '/ordertaskreport/filter/' + role + '/projects', request)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (response) => {
          this.store.dispatch(new UserReportsActions.SetProjects(response));
          this.store.dispatch(new UserReportsActions.StopLoading());
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }

  getConvertedDate(date: Date)
  {
    var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - date.getTimezoneOffset());
    return utcDate.toISOString().slice(0, 10); 
  }

  getReportsFilter(startDate: Date, endDate: Date) {    
    var startDateString = this.getConvertedDate(startDate);
    var endDateString = this.getConvertedDate(endDate);
    
    this.store.dispatch(new UserReportsActions.StartLoading());
    var filterDate = {
      "StartDate": startDateString,
      "EndDate": endDateString
    }
    this.http.post<UserReportsDateFilter>(environment.apiUrl + '/ordertaskreport/filter/init', filterDate)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(
        (filter) => {
          var isCoordinator = filter.userType === 'Manager' ? false : true;
          this.store.dispatch(new UserReportsActions.SetInitalFilter(filter));
          this.store.dispatch(new UserReportsActions.StopLoading());

          const managerId = filter.fields.managerIds.initialValue.applicationUserId;
          const coordinatorId = filter.fields.coordinatorIds.initialValue === null ? null : filter.fields.coordinatorIds.initialValue.applicationUserId;          

          this.getProjects(startDate, endDate, [managerId], [coordinatorId], isCoordinator);         
        }, (error) => {
          this.uiService.openSnack('Nieoczekiwany błąd', 'Błąd', 10_000);
          console.error(error);
          this.store.dispatch(new UserReportsActions.StopLoading());
        }
      );
  }
}
