import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAuth from './auth/auth.reducer';
import * as fromUi from './shared/ui.reducer';
import * as fromCommon from './shared/common.reducer';
import * as fromCompany from './redux/company/company.reducer';
import * as fromGroup from 'src/app/redux/group/group.reducer';
import * as fromProjectKind from 'src/app/redux/contractor/project-kind.reducer';
import * as fromAccesConfig from 'src/app/redux/access-config/access-config.reducer';
import * as fromUser from 'src/app/redux/user/user.reducer';
import * as fromRole from 'src/app/redux/role/role.reducer';
import { ScreenState } from 'src/app/redux/screen-state/screen-state';
import * as fromScreen from 'src/app/redux/screen-state/screen-state.reducer';
import * as fromContractor from 'src/app/redux/contractor/contractor.reducer';
import * as fromProject from 'src/app/redux/project/project.reducer';
import * as fromOrder from 'src/app/redux/order/order.reducer';
import * as fromService from 'src/app/redux/service/service.reducer';
import * as fromOrderTask from 'src/app/redux/order-task/order-task.reducer';
import * as fromProduct from 'src/app/redux/project/product.reducer';
import * as fromCustomField from 'src/app/redux/custom-fields/custom-field.reducer';
import * as fromLocalization from 'src/app/redux/localization/localization.reducer';
import * as fromEmployeeForm from 'src/app/redux/employee-form/employee-form.reducer';
import * as fromDictionary from 'src/app/redux/dictionary/dictionary.reducer';
import * as fromSurvey from 'src/app/redux/survey/survey.reducer';
import * as fromCentralPromotion from 'src/app/redux/central-promotion/central-promotion.reducer';
import * as fromUserReports from 'src/app/redux/user-reports/user-reports.reducer';
import * as fromClientTicket from 'src/app/redux/client-ticket/client-ticket.reducer';
import * as fromAppInfo from 'src/app/redux/app-information/app-information.reducer';
import * as fromExcelExport from 'src/app/redux/excel-export/excel-export.reducer';
import * as fromParamsAndTools from 'src/app/redux/params-and-tools/params-and-tools.reducer';
import * as fromAudit from 'src/app/redux/audit/audit.reducer';

export interface IRootState {
    auth: fromAuth.IAuthState;
    ui: fromUi.State;
    common: fromCommon.State;
    companyState: fromCompany.State;
    group: fromGroup.State;
    projectKind: fromProjectKind.State;
    user: fromUser.State;
    role: fromRole.State;
    screen: ScreenState;
    accessConfig: fromAccesConfig.State;
    contractor: fromContractor.State;
    project: fromProject.State;
    order: fromOrder.State;
    service: fromService.State;
    product: fromProduct.State;
    customField: fromCustomField.State;
    localization: fromLocalization.State;
    employeeForm: fromEmployeeForm.State;
    dictionary: fromDictionary.State;
    orderTask: fromOrderTask.State;
    survey: fromSurvey.State;
    centralPromotion: fromCentralPromotion.State;
    userReports: fromUserReports.State;
    clientTicket: fromClientTicket.State;
    appInfo: fromAppInfo.State;
    excelExport: fromExcelExport.State;
    paramsAndTools: fromParamsAndTools.State;
    audit: fromAudit.State;
}

export const reducers: ActionReducerMap<IRootState> = {
    auth: fromAuth.authReducer,
    ui: fromUi.uiReducer,
    common: fromCommon.commonReducer,
    companyState: fromCompany.companyReducer,
    group: fromGroup.groupReducer,
    projectKind: fromProjectKind.projectKindReducer,
    contractor: fromContractor.contractorReducer,
    user: fromUser.userReducer,
    role: fromRole.roleReducer,
    screen: fromScreen.screenReducer,
    accessConfig: fromAccesConfig.accessConfigReducer,
    project: fromProject.projectReducer,
    order: fromOrder.orderReducer,
    service: fromService.serviceReducer,
    product: fromProduct.productReducer,
    customField: fromCustomField.customFieldsReducer,
    localization: fromLocalization.localizationReducer,
    employeeForm: fromEmployeeForm.employeeFormReducer,
    dictionary: fromDictionary.dictionaryReducer,
    orderTask: fromOrderTask.orderTaskReducer,
    survey: fromSurvey.surveyReducer,
    centralPromotion: fromCentralPromotion.centralPromotionReducer,
    userReports: fromUserReports.userReportsReducer,
    clientTicket: fromClientTicket.clientTicketReducer,
    appInfo: fromAppInfo.appInfoReducer,
    excelExport: fromExcelExport.excelExportReducer,
    paramsAndTools: fromParamsAndTools.paramsAndToolsReducer,
    audit: fromAudit.auditReducer,
};

export const getScreenState = createFeatureSelector<ScreenState>('screen');
export const isMobile = createSelector(getScreenState, fromScreen.isMobile);
export const isTablet = createSelector(getScreenState, fromScreen.isTablet);
export const isDesktop = createSelector(getScreenState, fromScreen.isDesktop);

export const getUiState = createFeatureSelector<fromUi.State>('ui');
export const getIsLoading = createSelector(getUiState, fromUi.getIsLoading);

export const getAuthState = createFeatureSelector<fromAuth.IAuthState>('auth');
export const getIsAuth = createSelector(getAuthState, fromAuth.getIsAuth);
export const getAuthData = createSelector(getAuthState, fromAuth.getAuthData);
export const getRoles = createSelector(getAuthState, fromAuth.getRoles);
export const getCurrentUser = createSelector(getAuthState, fromAuth.getCurrentUser);
export const getCurrentCompany = createSelector(getAuthState, fromAuth.getCurrentCompany);
export const passwordConfig = createSelector(getAuthState, fromAuth.passwordConfig);
export const importProggress = createSelector(getAuthState, fromAuth.getImportProgress);
export const importStatus = createSelector(getAuthState, fromAuth.getImportStatus);
export const importLogs = createSelector(getAuthState, fromAuth.getImportLogs);
export const getContactModule = createSelector(getAuthState, fromAuth.getContactModule);
export const getRedirectUrl = createSelector(getAuthState, fromAuth.getRedirectUrl);
export const getRedirectParameters = createSelector(getAuthState, fromAuth.getRedirectParameters);

export const getCommonState = createFeatureSelector<fromCommon.State>('common');
export const getDictionaries = createSelector(getCommonState, fromCommon.getDictionaries);
export const getCurrentLanguage = createSelector(getCommonState, fromCommon.getCurrentLanguage);
export const getTranslationAdded = createSelector(getCommonState, fromCommon.getAddedTranslation);
export const getTranslationUpdated = createSelector(getCommonState, fromCommon.getUpdatedTranslation);

export const getCompanyState = createFeatureSelector<fromCompany.State>('companyState');
export const getCompanyList = createSelector(getCompanyState, fromCompany.companies);
export const getCompanyHrComponents = createSelector(getCompanyState, fromCompany.hrComponents);
export const getCompanyHrComponentGroups = createSelector(getCompanyState, fromCompany.hrComponentGroups);
export const getCompanyHrGroupedComponents = createSelector(getCompanyState, fromCompany.hrGroupedComponents);
export const getCompanyIsEditing = createSelector(getCompanyState, fromCompany.isEditing);
export const getCompanyIsEditingContact = createSelector(getCompanyState, fromCompany.isEditingContact);
export const getCompanySelected = createSelector(getCompanyState, fromCompany.selectedCompany);
export const getCompanyIsLoading = createSelector(getCompanyState, fromCompany.isLoading);

export const getGroupState = createFeatureSelector<fromGroup.State>('group');
export const getGroupList = createSelector(getGroupState, fromGroup.groups);
export const getGroupTotalCount = createSelector(getGroupState, fromGroup.groupsTotalCount);
export const getGroupManagersList = createSelector(getGroupState, fromGroup.managers);
export const getGroupIsEditing = createSelector(getGroupState, fromGroup.isEditing);
export const getGroupIsLoading = createSelector(getGroupState, fromGroup.isLoading);
export const getGroupSelected = createSelector(getGroupState, fromGroup.selectedGroup);

export const getUserState = createFeatureSelector<fromUser.State>('user');
export const getSelectedUser = createSelector(getUserState, fromUser.getSelectedUser);
export const getUserList = createSelector(getUserState, fromUser.getUsers);
export const getUsersTotalCount = createSelector(getUserState, fromUser.getUsersTotalCount);
export const getManagersListV2 = createSelector(getUserState, fromUser.getManagersV2);
export const getLocalizationCoordinatorsListV2 = createSelector(getUserState, fromUser.getLocalizationCoordinatorsV2);
export const getPmtCoordinatorsListV2 = createSelector(getUserState, fromUser.getPmtCoordinatorsV2);
export const getPromCoordinatorsListV2 = createSelector(getUserState, fromUser.getPromCoordinatorsV2);
export const getPmtMerchandisersListV2 = createSelector(getUserState, fromUser.getPmtMerchandisersV2);
export const getHostessesListV2 = createSelector(getUserState, fromUser.getHostessesV2);
export const getSupervisorsV2 = createSelector(getUserState, fromUser.getSupervisorsV2);
export const getSelectedGroupIds = createSelector(getUserState, fromUser.getSelectedGroupIds);


export const getContactDataSouce = createSelector(getUserState, fromUser.getContactDataSource);
export const getUserUpdated = createSelector(getUserState, fromUser.getUpdatedUser);
export const getUserOnline = createSelector(getUserState, fromUser.getOnline);
export const getUserIsLoading = createSelector(getUserState, fromUser.getIsLoading);
export const getUserIsLoaded = createSelector(getUserState, fromUser.getIsLoaded);
export const getUserIsediting = createSelector(getUserState, fromUser.getIsEditing);
export const getSelectedMerchGroupNames = createSelector(getUserState, fromUser.getSelectedMerchGroupNames);
export const getSelectedGroupMembers = createSelector(getUserState, fromUser.getSelectedGroupMembers);
export const getSelectedGroupManagers = createSelector(getUserState, fromUser.getSelectedGroupManagers);
export const getSearchUsers = createSelector(getUserState, fromUser.getSearchUsers);
export const getSelectedContactDetails = createSelector(getUserState, fromUser.getSelectedContactDetails);
export const getSelectedUserAdditionalData = createSelector(getUserState, fromUser.getSelectedUserAdditionalData);
export const getSelectedProjectKindGroupManagers = createSelector(getUserState, fromUser.getSelectedProjectKindGroupManagers);
export const getContactsReloadDataGrid = createSelector(getUserState, fromUser.getReloadDataGrid);


export const getRoleState = createFeatureSelector<fromRole.State>('role');
export const getRoleList = createSelector(getRoleState, fromRole.getRoles);
export const getRolesTotalCount = createSelector(getRoleState, fromRole.getRolesTotalCount);
export const getPermissionsList = createSelector(getRoleState, fromRole.getPermissions);
export const getRoleIsEditing = createSelector(getRoleState, fromRole.getRoleIsEditing);
export const getRoleUserIsEditing = createSelector(getRoleState, fromRole.getRoleUserIsEditing);
export const getRoleSelected = createSelector(getRoleState, fromRole.getRoleSelected);
export const getRoleUserSelected = createSelector(getRoleState, fromRole.getRoleUserSelected);
export const getRoleIsLoading = createSelector(getRoleState, fromRole.getIsLoading);

export const getProjectKindState = createFeatureSelector<fromProjectKind.State>('projectKind');
export const getProjectKindsList = createSelector(getProjectKindState, fromProjectKind.projectKinds);
export const getProjectKindRoleList = createSelector(getProjectKindState, fromProjectKind.projectKindRole);
export const getProjectKindAttachmentList = createSelector(getProjectKindState, fromProjectKind.projectKindAttachment);
export const getProjectKindIsEditing = createSelector(getProjectKindState, fromProjectKind.isEditing);
export const getProjectKindIsLoading = createSelector(getProjectKindState, fromProjectKind.isLoading);
export const getProjectKindSelected = createSelector(getProjectKindState, fromProjectKind.selectedProjectKind);

export const getAccessConfigState = createFeatureSelector<fromAccesConfig.State>('accessConfig');
export const getAccessConfigList = createSelector(getAccessConfigState, fromAccesConfig.accessConfigs);
export const getAccessConfigIsEditing = createSelector(getAccessConfigState, fromAccesConfig.isEditing);
export const getAccessConfigIsLoading = createSelector(getAccessConfigState, fromAccesConfig.isLoading);
export const getAccessConfigSelected = createSelector(getAccessConfigState, fromAccesConfig.selectedAccessConfig);

export const getContractorState = createFeatureSelector<fromContractor.State>('contractor');
export const getContractorsList = createSelector(getContractorState, fromContractor.contractors);
export const getContractorProjectKindsList = createSelector(getContractorState, fromContractor.contractorProjectKinds);
export const getContractorIsEditing = createSelector(getContractorState, fromContractor.isEditing);
export const getContractorIsLoading = createSelector(getContractorState, fromContractor.isLoading);
export const getContractorSelected = createSelector(getContractorState, fromContractor.selectedContractor);

export const getProjectState = createFeatureSelector<fromProject.State>('project');
export const getProjectsList = createSelector(getProjectState, fromProject.projects);
export const getProjectsForUserList = createSelector(getProjectState, fromProject.userProjects);
export const getProjectIsEditing = createSelector(getProjectState, fromProject.isEditing);
export const getProjectIsLoading = createSelector(getProjectState, fromProject.isLoading);
export const getProjectSelected = createSelector(getProjectState, fromProject.selectedProject);
export const getProjectSelectedForCharts = createSelector(getProjectState, fromProject.selectedProjectForCharts);
export const getProjectBudgetSelected = createSelector(getProjectState, fromProject.selectedProjectBudget);
export const getProjectBudgetReloadDataGrid = createSelector(getProjectState, fromProject.reloadDataGrid);

export const getProductState = createFeatureSelector<fromProduct.State>('product');
export const getProductList = createSelector(getProductState, fromProduct.products);
export const getLocalizationProductList = createSelector(getProductState, fromProduct.localizationProducts);
export const getSelectedProductsList = createSelector(getProductState, fromProduct.selectedProducts);
export const getProductPhotosList = createSelector(getProductState, fromProduct.photos);
export const getProductIsEditing = createSelector(getProductState, fromProduct.isEditing);
export const getProductIsLoading = createSelector(getProductState, fromProduct.isLoading);
export const getProductIsLoadingList = createSelector(getProductState, fromProduct.isLoadingList);
export const getProductSelected = createSelector(getProductState, fromProduct.selected);

export const getCustomFieldState = createFeatureSelector<fromCustomField.State>('customField');
export const getCustomFieldsList = createSelector(getCustomFieldState, fromCustomField.customFields);
export const getCustomPagesList = createSelector(getCustomFieldState, fromCustomField.customPages);
export const getCustomFieldIsEditing = createSelector(getCustomFieldState, fromCustomField.isEditing);
export const getCustomFieldIsLoading = createSelector(getCustomFieldState, fromCustomField.isLoading);
export const getCustomFieldSelected = createSelector(getCustomFieldState, fromCustomField.selectedCustomField);

export const getLocalizationState = createFeatureSelector<fromLocalization.State>('localization');
export const getLocalizationsList = createSelector(getLocalizationState, fromLocalization.localizations);
export const getProjectLocalizationsList = createSelector(getLocalizationState, fromLocalization.projectLocalizations);
export const getMarketsList = createSelector(getLocalizationState, fromLocalization.markets);
export const getLocalizationIsEditing = createSelector(getLocalizationState, fromLocalization.isEditing);
export const getLocalizationIsLoading = createSelector(getLocalizationState, fromLocalization.isLoading);
export const getLocalizationMarketsIsLoading = createSelector(getLocalizationState, fromLocalization.isLoadingMarkets);
export const getLocalizationIsSelected = createSelector(getLocalizationState, fromLocalization.selectedLocalization);
export const getMarketSelected = createSelector(getLocalizationState, fromLocalization.selectedMarket);
export const getSearchedLocalizationList = createSelector(getLocalizationState, fromLocalization.searchedLocalizationList);

export const getEmployeeFormState = createFeatureSelector<fromEmployeeForm.State>('employeeForm');
export const getEmployeeFormList = createSelector(getEmployeeFormState, fromEmployeeForm.employeeForms);
export const getEmployeeFormForUserList = createSelector(getEmployeeFormState, fromEmployeeForm.employeeFormsForUser);
export const getAllDocumentsList = createSelector(getEmployeeFormState, fromEmployeeForm.allDocuments);
export const getDocumentsApprovementList = createSelector(getEmployeeFormState, fromEmployeeForm.documentsApprovemnt);
export const getEmployeeFormIsEditing = createSelector(getEmployeeFormState, fromEmployeeForm.isEditing);
export const getEmployeeFormIsLoading = createSelector(getEmployeeFormState, fromEmployeeForm.isLoading);
export const getEmployeeFormSelected = createSelector(getEmployeeFormState, fromEmployeeForm.selected);

export const getOrderState = createFeatureSelector<fromOrder.State>('order');
export const getOrdersList = createSelector(getOrderState, fromOrder.orders);
export const getOrderIsEditing = createSelector(getOrderState, fromOrder.isEditing);
export const getOrderIsLoading = createSelector(getOrderState, fromOrder.isLoading);
export const getOrderSelected = createSelector(getOrderState, fromOrder.selectedOrder);
export const getOrderScheduleSelected = createSelector(getOrderState, fromOrder.selectedOrderSchedule);
export const getReportConfigList = createSelector(getOrderState, fromOrder.reportConfigList);
export const getOrderServerMessage = createSelector(getOrderState, fromOrder.message);
export const getOrderReloadDataGrid = createSelector(getOrderState, fromOrder.reloadDataGrid);

export const getExcelExportState = createFeatureSelector<fromExcelExport.State>('excelExport');
export const getExcelExportMessage = createSelector(getExcelExportState, fromExcelExport.message);

export const getServiceState = createFeatureSelector<fromService.State>('service');
export const getServicesList = createSelector(getServiceState, fromService.services);
export const getServicesForUserList = createSelector(getServiceState, fromService.userServices);
export const getServiceIsEditing = createSelector(getServiceState, fromService.isEditing);
export const getServiceIsLoading = createSelector(getServiceState, fromService.isLoading);
export const getServiceIsLoadingSM = createSelector(getServiceState, fromService.isLoadingSM);
export const getServiceSelected = createSelector(getServiceState, fromService.selectedService);
export const getSelectedServiceProjectKindId = createSelector(getServiceState, fromService.selectedServiceProjectKindId);
export const getServiceReloadDataGrid = createSelector(getServiceState, fromService.reloadDataGrid);
export const getServiceError = createSelector(getServiceState, fromService.error);
export const getServiceTotalSavings = createSelector(getServiceState, fromService.totalSavings);

export const getOrderTaskState = createFeatureSelector<fromOrderTask.State>('orderTask');
export const getTasksList = createSelector(getOrderTaskState, fromOrderTask.tasks);
export const getMyTasksList = createSelector(getOrderTaskState, fromOrderTask.myTasks);
export const getTaskProductsList = createSelector(getOrderTaskState, fromOrderTask.taskProducts);
export const getTaskPhotosList = createSelector(getOrderTaskState, fromOrderTask.taskPhotos);
export const getTasksGalleryList = createSelector(getOrderTaskState, fromOrderTask.tasksGallery);
export const getTaskIsEditing = createSelector(getOrderTaskState, fromOrderTask.isEditing);
export const getTaskIsLoading = createSelector(getOrderTaskState, fromOrderTask.isLoading);
export const getTaskGalleryIsLoading = createSelector(getOrderTaskState, fromOrderTask.isGalleryLoading);
export const getTaskSelected = createSelector(getOrderTaskState, fromOrderTask.selectedTask);
export const getChartOosByProductGroup = createSelector(getOrderTaskState, fromOrderTask.oosProductsByGroup);
export const getChartOosByLocalization = createSelector(getOrderTaskState, fromOrderTask.oosProductsByLocalization);
export const getChartOosBySalesRepresentative = createSelector(getOrderTaskState, fromOrderTask.oosProductsBySalesRepresentative);
export const getChartWorkTimeByMarket = createSelector(getOrderTaskState, fromOrderTask.workTimeByMarket);
export const getSurveyData = createSelector(getOrderTaskState, fromOrderTask.surveyData);
export const getTaskError = createSelector(getOrderTaskState, fromOrderTask.error);
export const getTempContainerRefreshed = createSelector(getOrderTaskState, fromOrderTask.tempContainerRefreshed);
export const getTaskReloadData = createSelector(getOrderTaskState, fromOrderTask.reloadData);

export const getDictionaryState = createFeatureSelector<fromDictionary.State>('dictionary');
export const getdictionariesList = createSelector(getDictionaryState, fromDictionary.dictionaries);
export const getDictionaryElementsList = createSelector(getDictionaryState, fromDictionary.elements);
export const getDictionaryIsEditing = createSelector(getDictionaryState, fromDictionary.isEditing);
export const getDictionaryIsLoading = createSelector(getDictionaryState, fromDictionary.isLoading);
export const getDictionarySelected = createSelector(getDictionaryState, fromDictionary.selectedDictionary);

export const getSurveyPatternState = createFeatureSelector<fromSurvey.State>('survey');
export const getSurveyPatternsList = createSelector(getSurveyPatternState, fromSurvey.surveyPatterns);
export const getSurveyPatternsListForProject = createSelector(getSurveyPatternState, fromSurvey.surveyPatternsForProject);
export const getOrderTasksForSurveyResults = createSelector(getSurveyPatternState, fromSurvey.orderTasksForResults);
export const getSurveyPatternIsEditing = createSelector(getSurveyPatternState, fromSurvey.isEditing);
export const getSurveyPatternIsLoading = createSelector(getSurveyPatternState, fromSurvey.isLoading);
export const getSurveyPatternSelected = createSelector(getSurveyPatternState, fromSurvey.selectedSurveyPattern);

export const getCentralPromotionState = createFeatureSelector<fromCentralPromotion.State>('centralPromotion');
export const getCentralPromotionList = createSelector(getCentralPromotionState, fromCentralPromotion.centralPromotions);
export const getCentralPromotionIsEditing = createSelector(getCentralPromotionState, fromCentralPromotion.isEditing);
export const getCentralPromotionIsLoading = createSelector(getCentralPromotionState, fromCentralPromotion.isLoading);
export const getCentralPromotionSelected = createSelector(getCentralPromotionState, fromCentralPromotion.selectedCentralPromotion);

export const getUserReportsState = createFeatureSelector<fromUserReports.State>('userReports');
export const getUserReportsInitialFilter = createSelector(getUserReportsState, fromUserReports.filterResult);
export const getUserReportsIsLoading = createSelector(getUserReportsState, fromUserReports.isLoading);
export const getUserReportsProjects = createSelector(getUserReportsState, fromUserReports.projects);
export const getUserReportsCoordinators = createSelector(getUserReportsState, fromUserReports.coordinators);
export const getUserReportsMarkets = createSelector(getUserReportsState, fromUserReports.markets);
export const getUserReportsResponsiblePersons = createSelector(getUserReportsState, fromUserReports.responsiblePersons);
export const getUserReports = createSelector(getUserReportsState, fromUserReports.reports);
export const getUserReportsDetails = createSelector(getUserReportsState, fromUserReports.reportsDetails);
export const getUserReportsOrders = createSelector(getUserReportsState, fromUserReports.orders);

export const getClientTicketState = createFeatureSelector<fromClientTicket.State>('clientTicket');
export const getClientTicketIsLoading = createSelector(getClientTicketState, fromClientTicket.isLoading);
export const getClientTicketList = createSelector(getClientTicketState, fromClientTicket.clientTicketList);
export const getClientTicketDetails = createSelector(getClientTicketState, fromClientTicket.clientTicketDetails);
export const getClientTicketFormDetails = createSelector(getClientTicketState, fromClientTicket.clientTicketFormDetails);
export const getClientTicketMessage = createSelector(getClientTicketState, fromClientTicket.message);

export const getAppInfoState = createFeatureSelector<fromAppInfo.State>('appInfo');
export const getAppInfoVersion = createSelector(getAppInfoState, fromAppInfo.version);

export const getParamsAndToolsState = createFeatureSelector<fromParamsAndTools.State>('paramsAndTools');
export const getParamsAndToolsIsLoadingProjects = createSelector(getParamsAndToolsState, fromParamsAndTools.isLoadingProjects);
export const getParamsAndToolsIsLoadingProjectKinds = createSelector(getParamsAndToolsState, fromParamsAndTools.isLoadingProjectKinds);
export const getParamsAndToolsIsLoadingMarkets = createSelector(getParamsAndToolsState, fromParamsAndTools.isLoadingMarkets);
export const getParamsAndToolsProjectList = createSelector(getParamsAndToolsState, fromParamsAndTools.projectList);
export const getParamsAndToolsProjectKindList = createSelector(getParamsAndToolsState, fromParamsAndTools.projectKindList);
export const getParamsAndToolsMarketsList = createSelector(getParamsAndToolsState, fromParamsAndTools.marketList);


export const getAuditState = createFeatureSelector<fromAudit.State>('audit');
export const getAuditIsLoading = createSelector(getAuditState, fromAudit.isLoading);
export const getSelectedAudit = createSelector(getAuditState, fromAudit.selectedAudit);