import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './auth/auth.guard';
import { PrivacyComponent } from './components/privacy/privacy.component';

export const HOME_PATH = 'home';
export const ADMINISTRATION_PATH = 'administration';
export const CONTACT_PATH = 'contact';
export const CONTRACTOR_LIST_PATH = 'contractor-list';
export const PROJECT_LIST_PATH = 'project-list';
export const ORDER_LIST_PATH = 'order-list';
export const SERVICE_LIST_PATH = 'service-list';
export const TASK_LIST_PATH = 'order-task-list';
export const WORK_TIME_LIST_PATH = 'work-time-list';
export const LOCALIZATION_LIST_PATH = 'localization-list';
export const EMPLOYEE_FORM_LIST_PATH = 'employee-form-list';
export const MY_TASKS_PATH = 'my-task-list';
export const SURVEY_DESIGNER_PATH = 'survey-designer';
export const SURVEY_PATTERN_LIST_PATH = 'survey-pattern-list';
export const CENTRAL_PROMOTION_LIST_PATH = 'central-promotion-list';
export const NOTIFICATIONS_LIST_PATH = 'notifications';
export const CALENDARS_LIST_PATH = 'calendars';
export const USER_REPORTS_LIST_PATH = 'user-reports-list';
export const CLIENT_TICKET_PATH = 'client-ticket';
export const SERVICE_DASHBOARD_PATH = 'service-dashboard';
export const AUDIT_PATH = 'audit-history';

const routes: Routes = [
    {
        path: '',
        loadChildren: './auth/auth.module#AuthModule',
    },
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
    },
    {
        path: CONTACT_PATH,
        loadChildren: './contact/contact.module#ContactModule',
        canLoad: [AuthGuard]
    },
    {
        path: ADMINISTRATION_PATH,
        loadChildren: './administration/administration.module#AdministrationModule',
        canLoad: [AuthGuard]
    },
    {
        path: HOME_PATH,
        loadChildren: './home/home.module#HomeModule',
        canLoad: [AuthGuard]
    },
    {
      path: CONTRACTOR_LIST_PATH,
      loadChildren: './contractor/contractor.module#ContractorModule',
      canLoad: [AuthGuard]
    },
    {
      path: PROJECT_LIST_PATH,
      loadChildren: './project/project.module#ProjectModule',
      canLoad: [AuthGuard]
    },
    {
      path: ORDER_LIST_PATH,
      loadChildren: './order/order.module#OrderModule',
      canLoad: [AuthGuard]
    },
    {
      path: SERVICE_LIST_PATH,
      loadChildren: './service/service.module#ServiceModule',
      canLoad: [AuthGuard]
    },
    {
      path: TASK_LIST_PATH,
      loadChildren: './order-task/order-task.module#OrderTaskModule',
      canLoad: [AuthGuard]
    },
    {
      path: MY_TASKS_PATH,
      loadChildren: './order-task/my-tasks.module#MyTasksModule',
      canLoad: [AuthGuard]
    },
    {
      path: WORK_TIME_LIST_PATH,
      loadChildren: './work-time/work-time.module#WorkTimeModule',
      canLoad: [AuthGuard]
    },
    {
      path: LOCALIZATION_LIST_PATH,
      loadChildren: './localization/localization.module#LocalizationModule',
      canLoad: [AuthGuard]
    },
    {
      path: EMPLOYEE_FORM_LIST_PATH,
      loadChildren: './employee-form/employee-form.module#EmployeeFormModule',
      canLoad: [AuthGuard]
    },
    {
      path: NOTIFICATIONS_LIST_PATH,
      loadChildren: './notifications/notifications.module#NotificationsModule',
      canLoad: [AuthGuard]
    },
    {
      path: CALENDARS_LIST_PATH,
      loadChildren: './calendars/calendar.module#CalendarModule',
      canLoad: [AuthGuard]
    },
    {
      path: SURVEY_DESIGNER_PATH,
      loadChildren: './survey/survey.module#SurveyModule',
      canLoad: [AuthGuard]
    },
    {
      path: SURVEY_PATTERN_LIST_PATH,
      loadChildren: './survey/survey.module#SurveyModule',
      canLoad: [AuthGuard]
    },
    {
      path: CENTRAL_PROMOTION_LIST_PATH,
      loadChildren: './central-promotion/central-promotion.module#CentralPromotionModule',
      canLoad: [AuthGuard]
    },
    {
      path: USER_REPORTS_LIST_PATH,
      loadChildren: './user-reports/user-reports.module#UserReportsModule',
      canLoad: [AuthGuard]
    },
    {
      path: CLIENT_TICKET_PATH,
      loadChildren: './client-ticket/client-ticket.module#ClientTicketModule',
      canLoad: [AuthGuard]
    },
    {
      path: SERVICE_DASHBOARD_PATH,
      loadChildren: './service-dashboard/service-dashboard.module#ServiceDashboardModule',
      canLoad: [AuthGuard]
    },
    {
      path: AUDIT_PATH,
      loadChildren: './audit/audit.module#AuditModule',
      canLoad: [AuthGuard]
    },
    {
        path: 'privacy',
        component: PrivacyComponent
    },
    { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: '**', redirectTo: 'auth/login' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
